import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import PriceSummary from '../components/priceSummary/PriceSummary';
import PriceDetails from '../stores/PriceDetails';

export default observer((props) => {
	const [priceDetails, setPriceDetails] = useState();
	const { customer, config, planId, subscriptionId, country, vatNumber, isMobile, couponCode, showCoupon, useBillingV2Client } = props;

	useEffect(() => {
		setPriceDetails(new PriceDetails({
			sumupService: config.sumupService,
			billingService: config.billingService,
			sumupSubscriptionsService: config.sumupSubscriptionsService,
			customerCountryCode: country,
			planId,
			subscriptionId,
			vatNumber,
			couponCode,
			useBillingV2Client
		}));
	}, [planId, subscriptionId, couponCode, country, vatNumber]);

	useEffect(() => {
		customer?.setPlanName(priceDetails?.planName);
	}, [priceDetails?.planName]);

	return (
		<PriceSummary
			priceDetails={priceDetails}
			isMobile={isMobile}
			couponCode={couponCode}
			showCoupon={showCoupon}
		/>
	);
});
