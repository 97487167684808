import Big from 'big.js';

export const toCents = (number) => {
	if (number === null || number === undefined) {
		return NaN;
	}

	return parseInt(toBig(number).times(100));
};

export const fromCents = (number) => {
	if (number === null || number === undefined) {
		return NaN;
	}

	return toFloat(toBig(number).div(100));
};

const toBig = (val) => {
	return new Big(val.toString());
};

const toFloat = (val, toDecimals = 2) => {
	return parseFloat(val.toFixed(toDecimals));
};