import wrapper from './api';
import { trace } from '../utils/notifyParent';
import { ApiError, SumupSubscriptionsError } from '../utils/errors';
import { TRACING_EVENTS } from '../../shared/constants';
import { SumupSubscriptionsMapper } from '../mappers/sumupSubscriptionsMapper';

function sumupSubscriptionsService({ token }) {
	const onError = (error => {
		const serviceIsUnavailable = error instanceof SumupSubscriptionsError
			&& (
				error.isNetworkError()
				|| error.isHTTPServiceUnavailable()
				|| error.isHTTPGatewayTimeout()
			);
		const details = error instanceof ApiError && error.details;

		if (serviceIsUnavailable) {
			trace(TRACING_EVENTS.SUMUP_SUBSCRIPTIONS_SERVICE_UNAVAILABLE, {
				details,
			});
		} else {
			trace(TRACING_EVENTS.SERVER_ERROR, {
				type: 'Sumup Subscriptions service',
				reason: error.message,
				details,
			});
		}
	});

	const api = wrapper({
		request: {
			baseURL: '/sumup-subscriptions/v1/clients',
			withCredentials: false,
			headers: {
				Authorization: `Bearer ${token}`,
				'Content-type': 'application/json',
			},
			errorHandled: true,
		},
		error: SumupSubscriptionsError,
		onError,
	});

	return {
		previewSubscriptionPurchase,
		purchaseSubscription
	};

	/**
	 * Preview subscription purchase
	 * @param {Object} params - The parameters for the preview, compatible with billingService#getSubscriptionPriceDetails
	 * @returns {Promise<Object>} - The preview response
	 */
	async function previewSubscriptionPurchase(params) {
		const requestData = SumupSubscriptionsMapper.mapPreviewRequest(params);
		const response = await api.post('/subscriptions/purchase/preview', requestData);
		return SumupSubscriptionsMapper.mapPreviewResponse(response.data);
	}

	/**
	 * Purchase subscription
	 * @param {Object} params - The parameters for the purchase, compatible with billingService#purchaseSubscription
	 * @returns {Promise<Object>} - The purchase response
	 */
	async function purchaseSubscription(params) {
		const requestData = SumupSubscriptionsMapper.mapPurchaseRequest(params);

		const response = await api.post('/subscriptions/purchase', requestData);
		return SumupSubscriptionsMapper.mapPurchaseResponse(response.data);
	}
}

export default sumupSubscriptionsService;
