import { observable, action, makeObservable } from 'mobx';
import { InitializationError } from '../utils/errors';

class PriceDetails {
	isLoaded = false;

	customerCountryCode;
	planId;
	subscriptionId;
	lines = [];
	productName;
	planName;
	planFrequency;
	nextChargeDate;
	currentChargeDate;
	couponCode;

	constructor({ sumupService, billingService, customerCountryCode, vatNumber, planId, subscriptionId, couponCode, sumupSubscriptionsService, useBillingV2Client }) {
		makeObservable(this, {
			isLoaded: observable,
			customerCountryCode: observable,
			planId: observable,
			subscriptionId: observable,
			lines: observable,
			productName: observable,
			planName: observable,
			planFrequency: observable,
			nextChargeDate: observable,
			currentChargeDate: observable,
			couponCode: observable,
			fetchPriceDetails: action
		});

		if (!sumupService || !billingService || !sumupSubscriptionsService) {
			throw new InitializationError('sumupService, billingService, and sumupSubscriptionsService should be provided');
		}

		this.sumupService = sumupService;
		this.billingService = billingService;
		this.sumupSubscriptionsService = sumupSubscriptionsService;
		this.useBillingV2Client = useBillingV2Client;

		Object.assign(this, {
			customerCountryCode, planId, vatNumber, subscriptionId, couponCode,
		});

		this.fetchPriceDetails();
	}

	async fetchPriceDetails() {
		if (this.isLoaded) {
			return;
		}

		try {
			this.isLoaded = false;
			let getPriceDetails;

			if (this.subscriptionId) {
				getPriceDetails = this.billingService.getUpdateSubscriptionPriceDetails.bind(this.billingService);
			}
			else {
				if (this.useBillingV2Client) {
					getPriceDetails = this.sumupSubscriptionsService.previewSubscriptionPurchase.bind(this.sumupSubscriptionsService);
				} else {
					getPriceDetails = this.billingService.getSubscriptionPriceDetails.bind(this.billingService);
				}
			}

			const result = await getPriceDetails({
				planId: this.planId,
				subscriptionId: this.subscriptionId,
				vatNumber: this.vatNumber,
				customerCountryCode: this.customerCountryCode,
				couponCode: this.couponCode,
			});

			Object.assign(this, result);
			this.isLoaded = true;
		} catch (err) { // ignore error
			err.propagate();
		}
	}
}

export default PriceDetails;
