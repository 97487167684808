import { PAYMENT_METHODS } from '../constants';
import { fromCents } from '../utils/formatAmount';

export class SumupSubscriptionsMapper {
	/**
	 * Maps the preview subscription purchase request to the format expected by the API
	 * @param {Object} params - The parameters for the preview, compatible with billingService#getSubscriptionPriceDetails
	 * @param {string} params.customerCountryCode - The customer country code
	 * @param {string} params.vatNumber - The VAT number
	 * @param {string} params.zipCode - The ZIP code
	 * @param {string} params.planId - The plan ID
	 * @param {number} params.quantity - The quantity of the subscription
	 * @returns {Object} - The mapped request
	*/
	static mapPreviewRequest({ customerCountryCode, vatNumber, zipCode, planId, quantity = 1 }) {
		return {
			customer: {
				country_code: customerCountryCode,
				vat_number: vatNumber,
				zip_code: zipCode
			},
			subscriptions: [
				{
					plan_id: planId,
					quantity
				}
			]
		};
	}

	/**
     * Maps the preview subscription purchase response to the format expected by the application
     * @param {Object} response - The response from the API
     * @returns {Object} - The mapped response
    */
	static mapPreviewResponse(response) {
		if (!response || !response.subscriptions || !response.subscriptions.length) {
			return response;
		}

		const lines = [];
		const { subscriptions, next_payment, subtotal, total, taxes, discount } = response;

		if (!!discount) {
			lines.push(
				{ type: 'discount', amount: -fromCents(discount.value), currency: discount.currency, rate: discount.rate },
			);
		}

		const { plan, price_details, trial } = subscriptions[0];
		const { net_amount } = price_details;

		lines.push({ type: 'net', amount: fromCents(net_amount.value), currency: net_amount.currency });

		if (taxes?.length > 0) {
			const tax = taxes[0];
			lines.push(
				{ type: 'tax', amount: fromCents(tax.amount.value), currency: tax.amount.currency, rate: tax.rate, taxType: tax.type },
			);
		}

		lines.push({ type: 'subtotal', amount: fromCents(subtotal.value), currency: subtotal.currency });
		lines.push(
			{ type: 'total', amount: fromCents(total.value), currency: total.currency },
		);

		const result = {
			nextChargeDate: next_payment.date,
			lines,
			planFrequency: plan.frequency,
			planName: plan.name,
			productName: plan.product.name,
			isTrialApplicable: !!trial?.period,
			trialPeriod: trial?.period,
			isInfiniteCoupon: false, // coupon is not supported in billing v2 yet
			isFullCouponEnabled: false // coupon is not supported in billing v2 yet
		};

		return result;
	}

	/**
	 * Maps the purchase subscription request to the format expected by the API
	 * @param {Object} params - The parameters for the purchase
	 * @param {string} params.purchaseReference - Unique reference for the purchase
	 * @param {string} params.customerReference - The customer reference
	 * @param {string} params.planId - The plan ID
	 * @param {number} params.quantity - The quantity of the subscription
	 * @param {string} params.paymentMethodType - The payment method type
	 * @param {Object} params.paymentMethodData - The payment method
	 * @returns {Promise<Object>} - The purchase response
	 */
	static mapPurchaseRequest({
		paymentMethodType,
		paymentMethodData,
		planId,
		purchaseReference,
		customerReference,
		quantity = 1,
	}) {
		return {
			reference: purchaseReference,
			customer: {
				merchant_code: customerReference,
				payment_method: this.mapPaymentMethodRequest(paymentMethodType, paymentMethodData),
			},
			subscriptions: [{ plan_id: planId, quantity }],
		};
	}

	/**
     * Maps the payment method request to the format expected by the API
     * @param {string} paymentMethodType - The type of payment method
     * @param {Object} paymentMethodData - The payment method data
     * @returns {Object} - The mapped payment method request
    */
	static mapPaymentMethodRequest(paymentMethodType, paymentMethodData) {
		if (paymentMethodType === PAYMENT_METHODS.creditCard) {
			return {
				type: 'credit_card',
				credit_card: {
					token: paymentMethodData.token,
					last_digits: paymentMethodData.last4,
					cardholder_name: paymentMethodData.cardholderName
				}
			};
		}

		if (paymentMethodType === PAYMENT_METHODS.directDebit) {
			return {
				type: 'direct_debit',
				direct_debit: {
					iban: paymentMethodData.iban,
					account_holder: paymentMethodData.accountHolder,
					mandate_reference: paymentMethodData.mandateReference,
				}
			};
		}

		if (paymentMethodType === PAYMENT_METHODS.suPayout) {
			return {
				type: 'su_payouts', // this is the expected name in Billing V2
				su_payouts: {
					merchant_code: paymentMethodData.merchantCode,
				}
			};
		}

		throw new Error(`Unsupported payment method type: ${paymentMethodType}`);
	}

	/**
     * Maps the purchase subscription response to the format expected by the application
     * @param {Object} response - The response from the API
     * @returns {Object} - The mapped response
    */
	static mapPurchaseResponse(response) {
		if (!response || !response.subscriptions || !response.subscriptions.length) {
			return response;
		}

		const subscription = response.subscriptions[0];

		return {
			success: true,
			subscriptionId: subscription.id,
			customerId: response.customer?.id,
			planId: subscription.plan?.id,
			charge: {
				id: response.bill_id,
				amountCents: subscription.price_details?.gross_amount?.value,
				currency: subscription.price_details?.gross_amount?.currency,
				endDate: subscription.next_billing_at,
				planId: subscription.plan?.id,
				status: 'succeeded'
			},
			paymentMethod: this.mapPaymentMethodResponse(response.payment_method)
		};
	}

	/**
     * Maps the payment method from the API response to the format expected by the application
     * @param {Object} paymentMethod - The payment method from the API response
     * @returns {Object} - The mapped payment method
    */
	static mapPaymentMethodResponse(paymentMethod) {
		if (!paymentMethod) {
			return null;
		}

		if (paymentMethod.type === 'credit_card') {
			return {
				type: 'creditCard',
				creditCard: {
					type: paymentMethod.credit_card?.type,
					last4: paymentMethod.credit_card?.last_digits || paymentMethod.credit_card?.last4
				}
			};
		}

		if (paymentMethod.type === 'direct_debit') {
			return {
				type: 'directDebit',
				directDebit: {
					maskedIban: paymentMethod.direct_debit?.masked_iban,
					accountHolder: paymentMethod.direct_debit?.accountholder_name
				},
			};
		}

		// su_payout payment method
		return paymentMethod;
	}
}
